<template>
  <div class="flex">
    <div class="mr-2">
      <Button label="Atras"
              icon="pi pi-arrow-left"
              class="btn btn-danger"
              @click="$router.go(-1)"
      />
    </div>
    <div class="mr-2">
      <Button label="Guardar / Actualizar"
              icon="pi pi-save"
              class="btn btn-info"
              @click="save"
      />
    </div>
    <div class="">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
            <i class="pi pi-list mr-2"/>
            Licitaciones
        </span>
        <dropdown placeholder="seleccione una licitacion"
                  class="p-inputtext-sm w-full"
                  v-model="selectedLicitacion"
                  :options="listLicitaciones"
                  :filter="true"
                  option-label="nombre"
                  option-value="id"
                  @change="onchangeLicitacion"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'LicIndVlrFilters',
  setup () {
    /** instancias **/
    const store = useStore()
    const toast = useToast()
    /** data **/
    const selectedLicitacion = ref(null)
    /** watch **/
    watch(selectedLicitacion, () => {
      store.commit('storelicitacionesIndicadoresValores/setSelectedLicitacion', selectedLicitacion.value)
    })
    /** computed **/
    const listLicitaciones = computed(() => {
      return store.getters['storelicitacionesIndicadoresValores/getLicitaciones']
    })
    /** methods **/
    const initComponent = () => {
      store.dispatch('storelicitacionesIndicadoresValores/storeListLicitaciones')
      store.dispatch('storelicitacionesIndicadoresValores/storeListIndicadoresTypes')
    }
    const onchangeLicitacion = ({ value: licitacionId }) => {
      console.log({ licitacionId })
      // store.dispatch('storelicitacionesIndicadoresValores/storeListIndicadoresValores', selectedLicitacion.value)
    }
    const save = () => {
      store.dispatch('storelicitacionesIndicadoresValores/storeIndicadoresValores').then((res) => {
        if (res) {
           // alert('Se guardo correctamente')
           toast.add({ severity: 'success', summary: 'Exito', detail: 'registro guardado', life: 3000 })
         } else {
           toast.add({ severity: 'error', summary: 'Error', detail: 'el registro no fue exitoso', life: 3000 })
         }
      })
    }
    /** hooks **/
    onMounted(() => {
      initComponent()
    })
    /** return **/
    return {
      initComponent,
      listLicitaciones,
      selectedLicitacion,
      onchangeLicitacion,
      save
    }
  }
}
</script>

<style scoped>

</style>
